import * as React from "react";
import { graphql, Link } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";

import Layout from "../components/layout";
import BlurredImage from "../components/blurred-image";
import SecondaryClouds from "../components/secondary-clouds";

import { useI18next } from "gatsby-plugin-react-i18next";

const BlogFilter = ({ data, name }) => {
  const { language } = useI18next();
  const blogPage = data.blog;
  return (
    <Layout>
      {data.tagSeo.nodes.map((current) => (
        <HelmetDatoCms seo={current.seoMetaTags} />
      ))}
      <main className="blog-page-container">
        <div className="blog-page-title">
          <h1>{blogPage.pageTitle}</h1>
          <div class="filters">
            <h6>{blogPage.filters}</h6>
            {data.tags.edges.map((tag) => (
              <Link
                to={`/blog/filter/${tag.node.slug}`}
                activeClassName="active"
                className="blog-tag"
                key={tag.node.id}
              >
                <span class="btn-text">{tag.node.tagName}</span>
              </Link>
            ))}
            <Link
              to={`/blog`}
              activeClassName="active"
              className="blog-tag-close"
            >
              <span class="btn-text">X</span>
            </Link>
          </div>
        </div>
        <section className="blog-posts-grid">
          {data.posts.edges.map(({ node: post }) => (
            <Link
              className="blog-post"
              dataTag={post.tag.tagName}
              key={post.id}
              to={`/blog/${post.slug}`}
              language={language}
            >
              <BlurredImage image={post.heroImage} />
              <h5>{post.title}</h5>
              <p>{post.excerpt}</p>
              <span className="tertiary-link">{blogPage.cta}</span>
            </Link>
          ))}
        </section>
        <SecondaryClouds />
      </main>
    </Layout>
  );
};

export default BlogFilter;

export const query = graphql`
  query BlogFilter($id: String!, $language: String!) {
    blog: datoCmsBlog(locale: { eq: $language }) {
      filters
      pageTitle
      cta
    }
    posts: allDatoCmsPost(
      sort: { fields: date, order: DESC }
      filter: { tag: { id: { eq: $id } } }
    ) {
      edges {
        node {
          id
          excerpt
          slug
          title
          date
          heroImage {
            url(imgixParams: { auto: "enhance", q: 100, w: "600" })
            alt
            width
            height
          }
          tag {
            tagName
          }
        }
      }
    }
    tagSeo: allDatoCmsBlogTag(
      filter: { locale: { eq: $language }, id: { eq: $id } }
    ) {
      nodes {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
      }
    }
    tags: allDatoCmsBlogTag(filter: { locale: { eq: $language } }) {
      edges {
        node {
          tagName
          id
          slug
        }
      }
    }
  }
`;
